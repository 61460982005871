.button {
    color: white;
    background: -webkit-linear-gradient(left, #d99b6a, #c56444);
    background-size: 200% 200%;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    border-width: 1px;
    padding: 13px;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.2em;
}

.button:hover {
    background: -webkit-linear-gradient(left, #d99b6a, #d99b6a);
}

.address {
    background-color: #bee3db;
    color: black;
    padding: 5px;
    border-radius: 5px;
    border: none;
}


/**
#e5771b, #753d16 

#e5771b, #e5771b

#e5771b

#c0ddea
*/